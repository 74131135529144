// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\css-loader\\dist\\cjs.js??ref--6-oneOf-1-1!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\loaders\\stylePostLoader.js!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\postcss-loader\\src\\index.js??ref--6-oneOf-1-2!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\postcss-loader\\src\\index.js??ref--6-oneOf-1-3!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\cache-loader\\dist\\cjs.js??ref--0-0!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\index.js??vue-loader-options!./TopNavSite.vue?vue&type=style&index=0&id=07feb95a&scoped=true&lang=css&");
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add CSS to Shadow Root
var add = require("!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-style-loader\\lib\\addStylesShadow.js").default
module.exports.__inject__ = function (shadowRoot) {
  add("892de2ae", content, shadowRoot)
};