<template>
<header id="topnavheader" class="header">
    <a v-if="!this.$kioskmode.value" href="#main-content" class="visually-hidden focusable skip-link">
        Skip to main content
        </a>
    <a class="logo" v-if="!this.$kioskmode.value" :href="this.logourl" title="9/11 Memorial | Home" rel="home">9/11 Memorial | Home</a>
    <a class="logo logo-kiosk" v-if="this.$kioskmode.value" :href="this.logourl" title="9/11 Memorial | Home" rel="home">9/11 Memorial | Home</a>
    <TopNavSite v-if="!this.$kioskmode.value" />
    <TopNavKiosk v-if="this.$kioskmode.value" />
</header>
</template>

<script>
import Vue from 'vue';
import TopNavSite from '@/components/TopNavSite.vue';
import TopNavKiosk from '@/components/TopNavKiosk.vue';

Vue.config.productionTip = false; 
Vue.config.silent = true;                       /* set to true to surpress all logs and warnings */
Vue.prototype.$kioskmode  = {value: false};
Vue.prototype.$siteURL = {value: ""};       

export default {
    name: 'topnavheader',
    components: {
        TopNavSite,
        TopNavKiosk
    },
    props: {
      siteurl: { type:String, default: ""},
      kioskmode: { type:String, default: ""}
    },
    data () {
        return {
            logourl : this.$siteURL.value
        }
    },
    mounted: function() {
      if (!this.$kioskmode.value) {
        this.logourl = "https://911memorial.org/";
      }
    },
    created: function() {
        // update global site URL with value passed in through component
        this.$siteURL.value = this.siteurl;

        if (this.kioskmode == "true") { 
          this.$kioskmode.value = true; 
        }

        // using CSS variables to change between website and kiosk's dark theme 
        if (this.$kioskmode.value) {
            document.documentElement.style.setProperty('--header-copy', '#fff'); 
            document.documentElement.style.setProperty('--bg-color', '#011523'); 
            document.documentElement.style.setProperty('--black-color', '#fff'); 
            document.documentElement.style.setProperty('--white-color', '#000'); 
            document.documentElement.style.setProperty('--main-background-color', '#011523');
            document.documentElement.style.setProperty('--header-shadow', '0'); 

        } else {
            document.documentElement.style.setProperty('--header-copy', '#333'); 
            document.documentElement.style.setProperty('--bg-color', '#fff'); 
            document.documentElement.style.setProperty('--black-color', '#000'); 
            document.documentElement.style.setProperty('--white-color', '#fff'); 
            document.documentElement.style.setProperty('--main-background-color', '#fff');
            document.documentElement.style.setProperty('--header-shadow', '0 1px 2px 0 rgba(0, 0, 0, 0.25)'); 
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/setup/_topnav.scss";

/*! CSS Used from: https://nineelevend8stg.prod.acquia-sites.com/sites/default/files/css/css_7eCu679ov0Dvdy7v_xRoHWezYQ4lpPi-87Wm7x_sJoU.css?pzoqs8 ; media=all */
@media all {
  .visually-hidden {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
  }
  .visually-hidden.focusable:active,
  .visually-hidden.focusable:focus {
    position: static !important;
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
  }
  @media screen and (min-width: 840px) {
    .responsive-menu-toggle-wrapper.responsive-menu-toggle {
      display: none;
    }
  }
}
/*! CSS Used from: https://nineelevend8stg.prod.acquia-sites.com/sites/default/files/css/css_rU4Rs6gh1RmzTYSiQBXK5Y3kGU2xrR4Qzht0bhgSemY.css?pzoqs8 ; media=all */
@media all {
  div,
  span,
  h2,
  h3,
  h4,
  .nav-main-user .nav-user-login-block .panel-title,
  p,
  a,
  strong,
  ul,
  li,
  fieldset,
  form,
  label,
  header,
  nav {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  header,
  nav {
    display: block;
  }
  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  ::-moz-selection {
    background: #007db3;
    color: #fff;
  }
  ::selection {
    background: #007db3;
    color: #fff;
  }
  h2,
  h3,
  h4,
  .nav-main-user .nav-user-login-block .panel-title {
    font-family: "Surveyor Fine A", "Surveyor Fine B", serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    color: #222;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2.5rem;
  }
  h4,
  .nav-main-user .nav-user-login-block .panel-title {
    font-size: 2rem;
  }
  strong {
    font-weight: 700;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  header a:focus,
  header button:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
  }
  a {
    color: #007db3;
    text-decoration: none;
    transition: none;
    -webkit-transition: none;
  }
  a:hover {
    color: #006088;
    cursor: pointer;
  }
  .header {
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 60px;
    top: 0;
    background-color: $main-background-color;
    -webkit-box-shadow: $header-shadow;
    box-shadow: $header-shadow;
  }
  @media (min-width: 61.8125em) {
    .header {
      min-height: 80px;
    }
  }
  .logo {
    display: block;
    position: absolute;
    top: 8px;
    left: 30px;
    width: 69px;
    height: 46px;
    text-indent: -999em;
    background: url(/assets/img/logo-full.svg);
    background-size: 284px;
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  .logo-kiosk {
    background: url(/assets/img/logo-full-white.svg);
  }
  @media (min-width: 52.5em) {
    .logo {
      top: 15px;
    }
  }
  @media (min-width: 61.8125em) {
    .logo {
      top: 30px;
      width: 284px;
      height: 20px;
      background-size: initial;
    }
  }
  header {
    position: relative;
  }

  .menu-content-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-family: "Gotham A", "Gotham B", Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #4d4d4d;
  }
  .field-name-field-text {
    color: #333;
  }
  .field-name-field-text p {
    margin-bottom: 1rem;
  }

  #topnavheader {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $main-background-color;
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 80px;
  }
}
</style>