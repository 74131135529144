<template>
    <nav aria-label="Site">
        <h2 id="site-nav-label" class="sr-only">Site Navigation</h2>
        <ul class="nav-menu" data-region="nav" aria-labelledby="site-nav-label">
            <li>
                <a :href="this.survivorsUrl" class="nav-link" >Witnesses &amp; Survivors</a>        
            </li>

            <li>
                <a :href="this.workersUrl" class="nav-link" >Rescue &amp; Recovery Workers</a>
            </li>

            <li>
                <a :href="this.memorialUrl" class="nav-link" >Memorials</a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    data () {
        return {
            survivorsUrl: this.$siteURL.value + '/#/survivors',
            workersUrl: this.$siteURL.value + '/#/workers',
            memorialUrl: this.$siteURL.value + '/#/memorials'
        }
    }
}
</script>

<style lang="scss" scoped>
  .nav-menu {
    margin-top: 30px;
    margin-left: 950px;
    list-style-type: none;
    li {
        float: left;
    }
  }
  .nav-link {
      padding: 0 40px 0 0;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
  }
  a.nav-link:hover {
      color: #fff;
  }
</style>