import { render, staticRenderFns } from "./TopNavSite.vue?vue&type=template&id=07feb95a&scoped=true&"
var script = {}
function injectStyles (context) {
  
  var style0 = require("./TopNavSite.vue?vue&type=style&index=0&id=07feb95a&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "07feb95a",
  null
  ,true
)

export default component.exports